import React from "react"
import { Maybe, SanityFlexibleGridItem } from "typings/graphql"
import { generateAltContentIndex } from "utils/altTextUtils"
import { getImage } from "utils/imageUtils"
import { tracking } from "utils/tracking"
import {
  ItemContainer,
  ItemImage,
  ItemLink,
  ItemText,
} from "./FlexibleGridItem.styles"
import { PortableText } from "components/UI/PortableText/PortableText"
import { ModeType } from "typings/modules"

type Props = {
  index: number
  item: Maybe<SanityFlexibleGridItem>
  mode: ModeType
}

export const FlexibleGridItem = ({ item, index, mode }: Props) => {
  const image = getImage(item?.image)
  const content = item?._rawContent
  const internalName = item?.internalName || ""
  const CtaLink = item?.CtaLink
  const CtaText = item?.CtaText
  const altDescription =
    image?.description ||
    generateAltContentIndex(content, index, item.internalName)

  return (
    <ItemContainer>
      <ItemImage image={image?.gatsbyImage} alt={altDescription} />
      {content && (
        <ItemText>
          <PortableText blocks={content} $mode={mode} />
        </ItemText>
      )}
      {CtaLink && CtaText && (
        <ItemLink
          to={CtaLink}
          onClick={() => {
            tracking.elementClicked(internalName, "Flexible Grid Item")
          }}
        >
          {CtaText}
        </ItemLink>
      )}
    </ItemContainer>
  )
}
