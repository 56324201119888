import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { mq, typography } from "styles"

export const ItemContainer = styled.div`
  grid-column: span 2;
  ${mq.minWidth("md")} {
    &:last-child:nth-child(3n - 1) {
      grid-column-end: -2;
    }
    &:nth-last-child(2):nth-child(3n + 1) {
      grid-column-end: 4;
    }
    &:last-child:nth-child(3n - 2) {
      grid-column-end: 5;
    }
  }
`

export const ItemImage = styled(GatsbyImage)`
  margin: auto;
  width: 96px;
  height: 96px;
`

export const ItemText = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 16px;
  p {
    ${typography.body};
    margin-bottom: 0;
  }
`

export const ItemLink = styled(Link)`
  ${typography.body};
  display: inline-flex;
  text-decoration: none;
  margin-top: 24px;
  border-bottom: 1px solid
    ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  transition: border 0.3s ease;
`
