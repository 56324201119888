import React from "react"
import { SanityFlexibleGridList } from "typings/graphql"
import { FlexibleGridList } from "./FlexibleGridList"

export const SanityFlexibleGridListBlock = ({
  index,
  fields,
}: {
  fields: SanityFlexibleGridList
  index: number
}) => <FlexibleGridList index={index} {...fields} />

export default SanityFlexibleGridListBlock
