import React, { useContext } from "react"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import { ThemeContext, ThemeProvider } from "styled-components"
import { ModeType } from "typings/modules"
import {
  Container,
  Items,
  Content,
  ButtonContainer,
} from "./FlexibleGridList.styles"
import { SanityFlexibleGridList } from "typings/graphql"
import { Carousel } from "components/UI/Carousel/Carousel"
import { Button, ButtonVariant } from "components/UI/Button/Button"
import { FlexibleGridItem } from "components/FlexibleGridItem/FlexibleGridItem"
import { tracking } from "utils/tracking"
import { PortableText } from "components/UI/PortableText/PortableText"

const carouselSettings = {
  className: "center",
  infinite: true,
  slidesToShow: 1,
  speed: 500,
}

type Props = {
  index: number
} & SanityFlexibleGridList

export const FlexibleGridList = (sanityProps: Props) => {
  const themeContext = useContext(ThemeContext)
  const internalName = sanityProps?.internalName
  const items = sanityProps?.items
  const bgColor = sanityProps?.bgColor?.opacityHex
  const mode = (sanityProps?.mode ?? "dark") as ModeType
  const content = sanityProps?._rawContent
  const { isMobile } = useCommonMediaQuery()
  const mobileLayout = sanityProps.mobileLayout ?? ""
  const ctaText = sanityProps.CtaText
  const ctaLink = sanityProps.CtaLink
  return (
    <ThemeProvider theme={{ localTheme: themeContext[mode] }}>
      <Container $bgColor={bgColor}>
        {content && (
          <Content>
            <PortableText blocks={content} $mode={mode} />
          </Content>
        )}
        {isMobile && mobileLayout === "carousel" ? (
          <Carousel settings={carouselSettings}>
            {items?.map((item, i) => (
              <FlexibleGridItem
                key={`flexible-grid-item-${i}`}
                index={i}
                item={item}
                mode={mode}
              />
            ))}
          </Carousel>
        ) : (
          <Items>
            {items?.map((item, i) => (
              <FlexibleGridItem
                key={`flexible-grid-item-${i}`}
                index={i}
                item={item}
                mode={mode}
              />
            ))}
          </Items>
        )}
        {ctaText && ctaLink && (
          <ButtonContainer>
            <Button
              to={ctaLink}
              type="button"
              variant={`primary-${mode}` as ButtonVariant}
              onClick={() => {
                tracking.elementClicked(internalName, "Value Prop List")
              }}
            >
              {ctaText}
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </ThemeProvider>
  )
}
