import styled from "styled-components"
import { mq } from "styles"
import { $BgColor } from "typings/modules"

export const Container = styled.div<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  text-align: center;
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  padding: 64px 0;
  .slick-slider {
    padding-bottom: 28px;
  }
  .slick-dots {
    bottom: -12px;
  }
  .slick-slide {
    padding: 0 0.83%;
  }
  ${mq.minWidth("md")} {
    white-space: nowrap;
    padding: 80px 12.5vw;
  }
  ${mq.minWidth("lg")} {
    padding: 96px 10.41vw;
  }
`

export const Content = styled.div`
  width: 83.33vw;
  margin: 0 auto 32px;
  text-align: center;
  white-space: normal;
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${mq.minWidth("md")} {
    width: 75vw;
    margin: 0 auto 64px;
  }
  ${mq.minWidth("lg")} {
    width: 41.66vw;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 52px;
  ${mq.minWidth("md")} {
    margin-top: 48px;
  }
  ${mq.minWidth("lg")} {
    margin-top: 64px;
  }
`

export const Items = styled.div`
  display: grid;
  grid-gap: 32px;
  padding: 0 16.66vw;
  ${mq.minWidth("md")} {
    grid-gap: 48px 10.4167vw;
    padding: 0;
  }
  ${mq.minWidth("lg")} {
    grid-gap: 64px 10.4167vw;
  }
  ${mq.minWidth("md")} {
    grid-template-columns: repeat(6, 1fr);
  }
`
