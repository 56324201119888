import { PortableTextType } from "typings/modules"
import { getFirstLineBlurb } from "utils/portableTextUtils"

export const generateAltContentIndex = (
  content: PortableTextType,
  index: number,
  internalName?: string
) => {
  const firstLine = getFirstLineBlurb(content)
  if (firstLine) {
    return `Image for ${firstLine}`
  }
  if (internalName) {
    return `Image ${index} for ${internalName}`
  }
  return `Image #${index}`
}
